import React from "react";

import { useWindowSizeType } from "@helpers/Hooks";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "@components/Hero/Hero";

export const HeroConsumables = () => {
	const windowSizeType = useWindowSizeType();
	const isMobile = windowSizeType === "mobile";
	const isTablet =
		windowSizeType === "tablet" || windowSizeType === "tablet_small";

	const props = {
		title: 'Streamline Your Consumables Management',
		description: 'Maximize your research efficiency with LIMS software that’s tailor-made for tracking biopharma consumables.',
		image: (
			<StaticImage
				loading="eager"
				src="../../_assets/consumable-layout.png"
				alt="Genemod Consumables Hero Image"
			/>
		)
	}

	return <Hero {...props} />
};

import React from "react";
import { PageLayout } from "@components/index";
import LabConsumablesCards from "./_components/LabConsumablesCards";
import IntegrateYourResearchV2 from "@components/IntegrateYourResearchV2/IntegrateYourResearchV2";
import { HeroConsumables } from "./_components/_ConsumablesHero/_ConsumablesHero";
import { StaticImage } from "gatsby-plugin-image";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import * as styles from "./lab-consumables.module.scss";
import Margins from "@components/Margins/Margins";

const cards: FeatureCardProps[] = [
	{
		title: "Custom Room Layouts for Your Biopharma Research",
		image: (
			<StaticImage
				src={"./_assets/consumables_img_1.png"}
				alt="Easily Manage and Submit Orders"
			/>
		),
		list: [
			"Create visual layouts specifically for biopharma benches, offices, and research spaces.",
			"Efficiently oversee and track biopharma consumables, making the most of your resources.",
			"Our virtual room editor for specialized biopharma spaces helps you cater to all your research needs.",
		],
	},
	{
		title: "Detailed Categorization",
		image: (
			<StaticImage
				src={"./_assets/consumables_img_2.png"}
				alt="Detailed Categorization"
			/>
		),
		list: [
			"Set up specific sections or categories designed for biopharma research consumables.",
			"Improve your organization by defining precise item groups within the biopharma context.",
			"This refined categorization gives you precise tracking, which is crucial for maintaining the integrity of your biopharma research.",
		],
		orientation: "right",
	},
	{
		title: "Collaborative Tracking of Biopharma Consumables",
		image: (
			<StaticImage
				src={"./_assets/consumables_img_3.png"}
				alt="Detailed Categorization"
			/>
		),
		list: [
			"Streamline how your monitor and update biopharma research consumable quantities.",
			"Easily document essential consumable data, such as lot numbers and activity, so that you maintain research compliance.",
			"Collaborative features all but guarantee consistent data integrity and alignment across your biopharma research team.",
		],
	},
];

const LabConsumables = () => {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "lab-consumables" }}
		>
			<Margins>
				<HeroConsumables />
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index} />;
					})}
				</FeatureCardLayout>
				<IntegrateYourResearchV2 />
				<LabConsumablesCards />
			</Margins>
		</PageLayout>
	);
};

export default LabConsumables;
